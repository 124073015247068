import React from 'react'
import './hero.scss'
import Embed from '../embed'

function Hero({ text, title, embed, id }) {
  return (
    <div id={id}>
      <div className="max-w-screen-xl mx-auto flex py-8 md:py-32 px-6">
        <div className="flex flex-wrap">
          <div className="w-full mb-6 md:mb-0 md:w-1/2 md:pr-10 flex flex-col justify-center">
            <h1 className="mb-4 text-gradient text-5xl md:text-6xl font-bold leading-none text-gray-900">
              {title}
            </h1>
            <p className="text-xl md:text-2xl text-gray-700">{text}</p>
          </div>
          <div className="w-full md:w-1/2 md:-ml-2">
            <Embed embed={embed} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Hero
