import React from 'react'
import { graphql } from 'gatsby'
import Startpage from './startpage'
import linkResolver from '../../contentful/linkResolver'

const StartpageContainer = ({ data }) => {
  const { contentfulStartpage } = data
  const startpage = {
    _doc: contentfulStartpage,
    locale: contentfulStartpage.node_locale,
    metaTitle: contentfulStartpage.metaTitle ? contentfulStartpage.metaTitle : contentfulStartpage.title,
    metaDescription: contentfulStartpage.metaDescription ? contentfulStartpage.metaDescription : null,
    ogType: 'page',
    title: contentfulStartpage.title,
    hero: {
      embed: contentfulStartpage.hero.embed
        ? contentfulStartpage.hero.embed.embed
        : null,
      heading:
        contentfulStartpage.hero !== null
          ? contentfulStartpage.hero.heading
          : '',
      text:
        contentfulStartpage.hero && contentfulStartpage.hero.text !== null
          ? contentfulStartpage.hero.text.text
          : '',
    },
    children: contentfulStartpage.childPages.map(node => {
      return {
        title: node.title,
        url: linkResolver(node),
      }
    }),
  }

  function getTeasers(teasers) {
    if (!teasers) return []
    return teasers.map(teaser => {
      return {
        title: teaser.title,
        text: teaser.text ? teaser.text.childMarkdownRemark.html : '',
        linkText: teaser.linkText,
        link: linkResolver(teaser.link),
        hash: teaser.slug,
        image: teaser.image ? teaser.image.fluid : null,
      }
    })
  }

  return (
    <Startpage
      doc={startpage}
      teasers={getTeasers(contentfulStartpage.teasers)}
    />
  )
}

export default StartpageContainer

export const pageQuery = graphql`
  query($locale: String!) {
    contentfulStartpage(node_locale: { eq: $locale }) {
      node_locale
      title
      metaTitle
      metaDescription
      hero {
        heading
        embed {
          embed
        }
        text {
          text
        }
      }
      teasers {
        title
        slug
        text {
          childMarkdownRemark {
            html
          }
        }
        link {
          slug
        }
        linkText
        image {
          fluid(maxWidth: 821, maxHeight: 493, quality: 70) {
            ...GatsbyContentfulFluid
          }
        }
      }
      childPages {
        ...ContentfulLink
      }
    }
  }
`
