import React from 'react'
import { Link } from 'gatsby'
import { Link as AnchorLink } from 'react-scroll'
import Hero from '../components/hero'
import Img from 'gatsby-image/withIEPolyfill'
import Html from '../components/html'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import Seo from '../components/seo'

const Startpage = ({ doc, teasers }) => {
  const bg = [
    'bg-blue-300 to-blue-500 from-blue-300',
    'bg-pink-500 to-pink-300 from-pink-500',
    'bg-orange-300 to-orange-500 from-orange-300',
    'bg-green-500 to-green-300 from-green-500',
    'bg-purple-300 to-purple-500 from-purple-300',
  ]

  const sections = teasers.map((teaser, i) => {
    return (
      <section
        tabIndex="-1"
        id={teaser.hash}
        key={i}
        className={`bg-gradient-to-r ${bg[i]}`}
      >
        <div className="max-w-screen-xl mx-auto flex flex-wrap py-8 px-6 md:py-32">
          <div
            className={`relative w-full md:w-1/3 flex justify-end flex-col wrap order-2 ${
              i % 2 == 0 ? 'md:order-2 pl-0' : 'md:order-1 pr-0'
            }`}
          >
            <Parallax className="z-10" y={[0, -20]} tagOuter="div">
              <div
                className={`bg-white px-6 pt-6 pb-8 md:pt-8 md:px-10 md:pb-12 md:mb-12 z-10 md:shadow-xl ${
                  i % 2 == 0 ? 'md:-ml-56' : 'md:-mr-56'
                } `}
              >
                <h2 className="text-3xl md:text-5xl font-bold leading-none mb-2 md:mb-4 text-gray-900">
                  {teaser.title}
                </h2>
                <div className="text-xl mb-5 md:mb-5 text-gray-700 leading-snug">
                  <Html>{teaser.text}</Html>
                </div>
                <Link
                  to={teaser.link}
                  className="text-xl font-bold text-gray-900 border-b-4 border-blue-500"
                >
                  {teaser.linkText}
                </Link>
              </div>
            </Parallax>
          </div>
          <div
            className={`w-full md:w-2/3 order-1 ${
              i % 2 == 0 ? 'md:order-1 pr-0' : 'md:order-2 pl-0'
            }`}
          >
            {teaser.image && (
              <Img
                objectFit="cover"
                objectPosition="50% 50%"
                placeholderClassName="blur"
                fluid={{ ...teaser.image }}
              />
            )}
          </div>
        </div>
      </section>
    )
  })

  const anchorsToSections = teasers.map((teaser, i) => {
    return (
      <li className="mx-4" key={i}>
        <AnchorLink
          className={`text-2xl hover rounded`}
          to={teaser.hash}
          spy={true}
          hashSpy={true}
          smooth={true}
          // offset={-220}
          duration={500}
          isDynamic={true}
          href={`#${teaser.hash}`}
          onClick={() => {
            setTimeout(() => {
              const container = document.getElementById(teaser.hash)
              container.focus()
            }, 600)
            // console.log('active')
            // if (i === 0) {
            //   history.replaceState(null, '', window.location.pathname)
            // } else {
            // history.replaceState(null, '', `#${teaser.hash}`)
            // }
          }}
          // onSetInactive={() => {
          //   console.log('inactive')
          //   // if (i === 0) {
          //   //   history.replaceState(null, '', window.location.pathname)
          //   // } else {
          //   // history.replaceState(null, '', `#${teaser.hash}`)
          //   // }
          // }}
        >
          {teaser.title}
        </AnchorLink>
      </li>
    )
  })

  return (
    <ParallaxProvider>
      <main>
        <Seo
          title={doc.metaTitle}
          description={doc.metaDescription}
          ogImage={doc.ogImage}
          ogType={doc.ogType}
          locale={doc.locale}
          hideSiteName={true}
        ></Seo>
        <nav
          className="hidden lg:flex justify-center -mt-24 mb-8 py-2"
          aria-label="Sidmeny (filmer)"
        >
          <ul className="flex">{anchorsToSections}</ul>
        </nav>
        <Hero
          text={doc.hero.text}
          title={doc.hero.heading}
          embed={doc.hero.embed}
          id="innehall"
        />
        <div id="sections">{sections}</div>
      </main>
    </ParallaxProvider>
  )
}

export default Startpage
